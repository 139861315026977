import useSWR from "swr";
import { FlagKey } from "@trunk-tools/txt-shared";
import { api } from "@/dataHooks/helpers/APIClient";
import { useMaybeCurrentProject } from "./project.datahook";

export const useFeatureFlag = (
  getFlagKey: (arg: typeof FlagKey) => FlagKey,
) => {
  const { currentProject } = useMaybeCurrentProject();

  const flagKey = getFlagKey(FlagKey);

  const { data: flagEnabled, mutate: refetchFlagEnabled } = useSWR(
    flagKey ? ["FlagEnabled", flagKey] : null,
    () =>
      api.flagEnabled({
        flag_key: flagKey,
        business_id: currentProject?.business_id,
        project_id: currentProject?.id,
      }),
    { suspense: true },
  );

  if (!flagKey) {
    throw new Error(
      "No flag key returned from the getFlagKey selector function",
    );
  }

  const { enabled } = flagEnabled || {};

  return {
    flagEnabled: enabled,
    refetchFlagEnabled,
  };
};
