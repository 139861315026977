import { Navigate } from "react-router-dom";
import { useIsLoggedIn, useMe, useUserProjects } from "dataHooks";
import { FeatureFlagGate } from "@/components/FeatureFlagGate.component";
import { useAuth } from "@frontegg/react";
import { api } from "@/dataHooks/helpers/APIClient";

const LoggedInIndexPage = () => {
  const { projects } = useUserProjects({});
  const { me, changeProject } = useMe();

  if (projects.length === 0) {
    return <Navigate to={"/no-projects"} replace />;
  }

  let projectId: string | null = null;

  if (me.last_project_id) {
    const lastProject = projects.find((p) => p.id === me.last_project_id);

    // there's a chance that the last project id isn't actually a project that the user has access to anymore
    if (lastProject) {
      projectId = lastProject.id;
    }
  }

  if (!projectId) {
    projectId = projects[0].id;
    changeProject(projectId);
  }

  return <Navigate to={`/projects/${projectId}/conversations`} replace />;
};

const NonFEIndexPage = () => {
  const { isLoggedIn } = useIsLoggedIn();

  return isLoggedIn ? (
    <LoggedInIndexPage />
  ) : (
    <Navigate to={`/login`} replace />
  );
};

const FEIndexPage = () => {
  const { user, isAuthenticated } = useAuth();

  if (user) {
    api.setJwtToken(user.accessToken);
  }

  return isAuthenticated ? (
    <LoggedInIndexPage />
  ) : (
    <Navigate to={`/account/login`} replace />
  );
};

export const IndexPage = () => (
  <FeatureFlagGate
    enabled={() => <FEIndexPage />}
    disabled={() => <NonFEIndexPage />}
    flagKey={(FlagKey) => FlagKey.Frontegg}
  />
);
