import { TouchEvent, useRef } from "react";

interface SwipeInput {
  onSwipedLeft: () => void;
  onSwipedRight: () => void;
}

interface SwipeOutput {
  onTouchStart: (e: TouchEvent) => void;
  onTouchMove: (e: TouchEvent) => void;
  onTouchEnd: (e: TouchEvent) => void;
}

export const useSwipe = (input: SwipeInput): SwipeOutput => {
  const touchStartClientX = useRef(0);
  const touchEndClientX = useRef(0);

  const minSwipeDistance = 250;

  const onTouchStart = (e: TouchEvent) => {
    touchStartClientX.current = e.targetTouches[0].clientX;
    touchEndClientX.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e: TouchEvent) => {
    touchEndClientX.current = e.targetTouches[0].clientX;
  };

  const onTouchEnd = (e: TouchEvent) => {
    const distance = touchStartClientX.current - touchEndClientX.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe || isRightSwipe) {
      // prevent all defaults and propagation
      e.preventDefault();
      e.stopPropagation();
    } else {
      return;
    }

    if (isLeftSwipe) {
      input.onSwipedLeft();
    } else if (isRightSwipe) {
      input.onSwipedRight();
    }
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};
