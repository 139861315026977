import { FronteggThemeOptions } from "@frontegg/react";
import { CustomLoginFooter } from "./CustomLoginFooter";

export const themeOptions: FronteggThemeOptions = {
  loginBox: {
    boxStyle: {
      "& button": {
        marginTop: "3px !important",
      },
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      border: "solid black 1px",
      padding: "2.25rem !important",
      paddingBottom: "3rem !important",
    },
    boxFooter: () => <CustomLoginFooter />,
  },
};
