import { forwardRef, useState } from "react";
import {
  IoAddOutline,
  IoCalendarOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
  IoFilterOutline,
} from "react-icons/io5";
import { Button, DatePicker, Modal } from "@trunk-tools/ui";

const ICON_CLASSNAME = "w-6 h-6";

const FakeInputDateTrigger = forwardRef<
  HTMLDivElement,
  { label: string } & React.HTMLProps<HTMLDivElement>
>(({ label, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className="flex flex-row items-center gap-3 justify-between bg-yellow-50 rounded-sm px-4 py-3 cursor-pointer"
      {...props}
    >
      <p className="text-gray-700">{label}</p>
      <IoCalendarOutline className="w-4 h-4 text-gray-700" />
    </div>
  );
});

const CorpusFiltersPanel = ({ onClose }: { onClose: () => void }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-6 overflow-y-auto max-w-full desktop:w-[800px]">
        <div className="flex flex-row flex-wrap desktop:flex-nowrap gap-4 items-center justify-between">
          <h6 className="uppercase">Select or Create a Filter</h6>
          <div className="flex justify-end">
            <Button
              icon={IoAddOutline}
              iconClassName="w-4 h-4"
              variant="success"
              size="sm"
              onClick={() => setModalOpen(true)}
            >
              Save Custom Filter
            </Button>
          </div>
        </div>
        <div className="bg-[#0F151A7A] border border-[#0D1217] p-4 rounded">
          <div className="flex flex-col desktop:flex-row flex-wrap desktop:items-center gap-3 desktop:gap-2">
            <p className="desktop:inline-block">Last modified between</p>
            <div className="desktop:inline-block">
              <DatePicker
                triggerElement={
                  <FakeInputDateTrigger label="Select Date Range Start" />
                }
                value={new Date()}
              />
            </div>
            <p className="desktop:inline-block">and</p>
            <div className="desktop:inline-block">
              <DatePicker
                triggerElement={
                  <FakeInputDateTrigger label="Select Date Range End" />
                }
                value={new Date()}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row flex-wrap gap-4 desktop:flex-nowrap items-center justify-between mt-5">
          <div className="hidden desktop:block">
            <Button
              variant="secondary"
              size="lg"
              icon={IoCloseOutline}
              iconClassName={ICON_CLASSNAME}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
          <div className="flex flex-row items-center gap-4 justify-between w-full desktop:w-auto">
            <Button
              variant="secondary"
              size="lg"
              icon={IoFilterOutline}
              iconClassName={ICON_CLASSNAME}
            >
              Apply
            </Button>
            <Button
              variant="success"
              size="lg"
              icon={IoCheckmarkOutline}
              iconClassName={ICON_CLASSNAME}
            >
              Save &amp; Apply
            </Button>
          </div>
        </div>
      </div>
      <Modal
        title="Save Custom Filter"
        content={<div></div>}
        open={modalOpen}
        setOpen={setModalOpen}
      />
    </>
  );
};

export const CorpusFilters = ({
  triggerComponent,
}: {
  triggerComponent: ({
    setModalOpen,
  }: {
    setModalOpen: (open: boolean) => void;
  }) => React.ReactNode;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      {triggerComponent({ setModalOpen })}
      <Modal
        title="Filter"
        content={
          <CorpusFiltersPanel
            onClose={() => {
              setModalOpen(false);
            }}
          />
        }
        open={modalOpen}
        setOpen={setModalOpen}
      />
    </>
  );
};
