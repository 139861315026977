import { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";

import { AnswerMessage } from "./AnswerMessage.component";
import { AgentAnsweredQuestionConversationItem } from "./ConversationItems/AgentAnsweredQuestionConversationItem.component";
import { ConversationQuestionItem } from "@trunk-tools/txt-shared";
import { Button, MarkdownFormatter } from "@trunk-tools/ui";
import { IoArrowRedo } from "react-icons/io5";
import dayjs from "dayjs";
import { useMarkQuestionBankAgentConversationAsRead } from "@/dataHooks/agents.dataHook";
import { useIsTabActive } from "@/hooks/useIsTabActive";

export const QuestionBankAgentSummary = ({
  questions,
  ids,
}: {
  questions: ConversationQuestionItem[];
  ids: string[];
}) => {
  const [agentQuestionCollapsed, setAgentQuestionCollapsed] = useState(false);
  const { markQuestionBankAgentConversationRead } =
    useMarkQuestionBankAgentConversationAsRead({
      question_bank_agent_question_ids: ids,
    });
  const isTabActive = useIsTabActive();

  useEffect(() => {
    if (questions[0].question.state === "ANSWERED" && isTabActive) {
      markQuestionBankAgentConversationRead();
    }
  }, [questions[0].question.state, isTabActive]);

  return (
    <>
      <AgentAnsweredQuestionConversationItem
        collapsedHeaderContent={
          <>
            <p className="text-sm">
              {dayjs(questions[0].question.created_at).format(
                "MMM D, YYYY, h:mm A",
              )}
            </p>
            <p className="text-xl font-bold mb-3 mt-1">
              {
                questions[0].question.question_bank_agent_question[0]
                  .question_bank_agent_config.name
              }
            </p>
          </>
        }
        collapsible
        onCollapse={() => {
          setAgentQuestionCollapsed(!agentQuestionCollapsed);
        }}
        isLastItem={agentQuestionCollapsed}
      >
        <span>
          <Button
            size="sm"
            isInline
            onClick={async () => {
              const text = questions
                .map(
                  (question) =>
                    `Question: ${question.question.question_text}: \n\nAnswer: ${question.question.answer_text}`,
                )
                .join("\n\n");
              const html = renderToString(
                <>
                  {questions.map((question, i) => (
                    <>
                      <h4>
                        Question {i + 1}: {question.question.question_text}
                      </h4>
                      <MarkdownFormatter
                        withoutDownloadButton
                        text={question.question.answer_text}
                      />
                    </>
                  ))}
                </>,
              );
              await navigator.clipboard.write([
                new ClipboardItem({
                  "text/plain": new Blob([text], {
                    type: "text/plain",
                  }),
                  "text/html": new Blob([html], {
                    type: "text/html",
                  }),
                }),
              ]);
            }}
          >
            <IoArrowRedo className="w-5 h-5 rotate-180" />
            <p className="uppercase tracking-normal text-xs">
              copy all questions & answers to clipboard
            </p>
          </Button>
        </span>
      </AgentAnsweredQuestionConversationItem>

      {!agentQuestionCollapsed &&
        questions.map((question, index) => {
          return (
            <AnswerMessage
              embeddedQuestion={true}
              index={index}
              question={question.question}
              last={index === questions.length - 1}
              key={question.question.id}
            />
          );
        })}
    </>
    // and then down here, map over all the qustions
  );
};
