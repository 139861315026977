import txtLogo from "public/TT_white.svg";
import { Navigate } from "react-router-dom";
import { GradientBackdrop } from "@trunk-tools/ui";
import { useUserProjects } from "dataHooks";

export const NoProjectsPage = () => {
  const { projects } = useUserProjects({});

  if (projects.length > 0) {
    return <Navigate to={"/"} replace />
  }

  return (
    <GradientBackdrop>
      <div className="h-full flex flex-col items-center h-screen mt-[27vh]">
        <img
          src={txtLogo}
          alt="TrunkText logo"
          className="max-w-[440px] w-[60vw] mb-4"
        />
        <div className="bg-blue-700/50 rounded p-4 w-fit mx-5 mt-5">
          <h1 className="text-2xl mb-2 font-extrabold">No Projects Found</h1>
          <div className="mb-5">
            Your user is not associated with any projects.<br></br>
            Please reach out to your IT team and <a href="mailto:support@trunk.tools" className="underline">support@trunk.tools</a> for assistance.
          </div>
        </div>
      </div>
    </GradientBackdrop>
  );
}
