import { Avatar, PopupMenu, PopupMenuItemTypes } from "@trunk-tools/ui";
import { useMe } from "dataHooks";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { useFeatureFlag } from "@/dataHooks/featureFlag.dataHook";
import { AdminPortal } from "@frontegg/react";

export const UserIconMenu = () => {
  const { me } = useMe();
  const idNavigate = useIdNavigate();
  const { flagEnabled } = useFeatureFlag((ff) => ff.Agents);
  const { flagEnabled: fronteggFlagEnabled } = useFeatureFlag(
    (ff) => ff.Frontegg,
  );

  if (!me) return null;

  const popupItems: PopupMenuItemTypes[] = [
    {
      type: "button",
      onClick: () => {
        window.location.href = fronteggFlagEnabled
          ? "/account/logout"
          : "/logout";
      },
      label: "LOG OUT",
    },
  ];

  if (fronteggFlagEnabled) {
    popupItems.unshift({
      type: "button",
      onClick: () => {
        AdminPortal.show();
      },
      label: "USER/SSO MANAGEMENT",
    });
  }

  if (flagEnabled) {
    popupItems.unshift({
      type: "button",
      onClick: () => idNavigate("/agents"),
      label: "MANAGE AGENTS",
    });
  }

  return (
    <PopupMenu
      titleContent={
        <div className="flex flex-row gap-x-4">
          <div className="flex flex-col justify-around">
            <Avatar
              initials={`${me.first_name[0]}${me.last_name[0]}`}
              variant="v2blue"
            />
          </div>
          <div className="flex flex-col justify-around">
            {me.first_name} {me.last_name}
          </div>
        </div>
      }
      items={popupItems}
    />
  );
};
