import { Conversation } from "@/components/Conversations/Conversation.component";
import { GradientBackdrop } from "@trunk-tools/ui";
import { useState } from "react";
import { AskPageNavbar } from "@/components/AskPageComponents/AskPageNavbar.component";
import { AskPageSidebar } from "@/components/AskPageComponents/AskPageSidebar.component";
import { useSwipe } from "@/hooks/useSwipe";

export const AskPage = () => {
  const [sidebarIsOpen, setSidebarOpen] = useState(false);

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => setSidebarOpen(false),
    onSwipedRight: () => setSidebarOpen(true),
  });

  return (
    <GradientBackdrop>
      <div
        className={`transition-all h-full desktop:ml-[320px] ${
          sidebarIsOpen ? "ml-0" : ""
        }`}
      >
        <div
          className={`transition-all fixed top-0 left-0 p-6 h-full transition-transform overflow-y-auto w-[320px] ${
            sidebarIsOpen ? "" : "max-desktop:-translate-x-full"
          }`}
        >
          <AskPageSidebar setSidebarOpen={setSidebarOpen} />
        </div>
        <div
          {...swipeHandlers}
          className={`transition-all h-full flex flex-col justify-between ${
            sidebarIsOpen
              ? "fixed translate-x-[320px] desktop:translate-x-0"
              : ""
          }`}
          onClick={() => {
            if (sidebarIsOpen) {
              setSidebarOpen(false);
            }
          }}
        >
          <AskPageNavbar toggleSidebar={() => setSidebarOpen(!sidebarIsOpen)} />
          <Conversation />
        </div>
      </div>
    </GradientBackdrop>
  );
};
